import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Popup, Dropdown, Icon, Input, Form, TextArea } from 'semantic-ui-react';
import _ from "underscore";
import TwilioDevice from "../Twilio/TwilioDevice";
import axios from "axios";

const CallPopup = (props) => {
  const { openPhonePopup, handlers, prospectId } = props;

  const dpi = props.dataProviderInformation;
  const [phoneNumber, setPhoneNumber] = useState(dpi.userSanitizedValue || dpi.value || '');
  const [dataProviderInformation, setDataProviderInformation] = useState(dpi);

  const [tempNotes, setTempNotes] = useState('');
  const [notes, setNotes] = useState('');

  const [callInProgress, setCallInProgress] = useState(false);
  const [outcomes, setOutcomes] = useState([]);

  const [callData, setCallData] = useState({});

  const notesRef = useRef(null);
  const callOutcomeRef = useRef(null);
  const popupRef = useRef(null);

  const debouncedSetNotes = useCallback(
    _.debounce((value) => {
      setNotes(value);
    }, 300),
    []
  );

  const updateNotes = () => {
    if (callData.callSid) {
      axios.patch(`/phone_calls/${callData.callSid}/update_notes`, { notes: notes })
    }
  }

  useEffect(() => {
    updateNotes()
  }, [notes]);

  const updateOutcome = (outcomeId) => {
    callOutcomeRef.current = outcomeId
    if (callData.callSid) {
      axios.patch(`/phone_calls/${callData.callSid}/update_outcome`, { outcome_id: outcomeId })
    }
  }

  const getPhoneCallOutcomes = () => {
    axios.get(`/phone_calls/outcomes`).then(({ data }) => {
      setOutcomes(data);
    })
  }

  useEffect(() => {
    getPhoneCallOutcomes()
  }, []);

  useEffect(() => {
    if (!dataProviderInformation.id || !openPhonePopup) return;

    axios.post(
      `/data_provider_information/${dataProviderInformation.id}`,
      { prospectId: prospectId, userSanitizedValue: phoneNumber }
    )
    setDataProviderInformation({ ...dataProviderInformation, userSanitizedValue: phoneNumber });
  }, [phoneNumber]);

  useEffect(() => {
    if (props.dataProviderInformation.value !== dataProviderInformation.value) {
      setDataProviderInformation(props.dataProviderInformation);
      setPhoneNumber(props.dataProviderInformation.userSanitizedValue || props.dataProviderInformation.value);
    }
  }, [props.dataProviderInformation]);

  return (
    <Popup
      on='click'
      onClose={(e) => {
        // Inside the popup we have 2 other popups, we don't want to close the main popup when clicking on them
        // and this parentNode loop is a hack to prevent that
        let target = e.target;
        for (let i = 0; i < 10; i++) {
          if (target.classList.contains('audioDevicesPopup') || target.classList.contains('keyboardPopup')) return;

          target = target.parentNode;

          if (!target.classList) break;
        }

        handlers.setState({ openPhonePopup: null })
        handlers.loadProspect();
      }}
      onOpen={() => handlers.setState({ openPhonePopup: dataProviderInformation.value })}
      open={openPhonePopup === dataProviderInformation.value}
      trigger={<Button icon style={{ marginLeft: 5 }}><Icon name='phone' /></Button>}
      position='bottom center'
      wide={true}
      style={{ minWidth: 460, maxWidth: 460 }}
      ref={popupRef}
      id='phonePopup'
      className="phonePopup"
    >
      <TwilioDevice
        prospectId={prospectId}
        horizontal={true}
        setCallInProgress={setCallInProgress}
        selectedDataProviderInformation={dataProviderInformation}
        setCallData={setCallData}
      >
        <Input
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
            setDataProviderInformation({ ...dataProviderInformation, userSanitizedValue: e.target.value });
          }}
          disabled={!!callInProgress}
          style={{ marginRight: 10 }}
        />
      </TwilioDevice>
      <Form.Field>
        <label>Call Outcome</label>
        <Dropdown
          fluid
          selection
          options={outcomes.map(o => ({ key: o.id, value: o.id, text: o.name }))}
          style={{ marginBottom: 10 }}
          onChange={(_e, element) => updateOutcome(element.value)}
        />
      </Form.Field>
      <Form.Field>
        <label>Notes</label>
        <TextArea
          value={tempNotes}
          onChange={(e) => {
            setTempNotes(e.target.value)
            debouncedSetNotes(e.target.value)
          }}
          ref={notesRef}
          style={{
            width: '100%',
            height: '100px',
            border: '1px solid #ccc',
            borderRadius: '8px',
            padding: '8px',
            resize: 'none',
          }}
        />
      </Form.Field>
    </Popup>
  );
};

export default CallPopup;
