import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { Grid, Dropdown, Icon, Button, Popup } from 'semantic-ui-react';
import _ from "underscore";
import AudioMeter from './AudioMeter';
import axios from "axios";
import { present } from '../utils';

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

const TwilioCall = (props) => {
  const { device, selectedDataProviderInformation, coldCallingSession } = props;

  // NOTE: We can not perform any action if device is not initialized
  if (!device) { return null }

  const [callInProgress, setCallInProgress] = useState(false);
  const [microphones, setMicrophones] = useState([]);
  const [activeMicrophone, setActiveMicrophone] = useState(null);
  const [speakers, setSpeakers] = useState([]);
  const [activeSpeaker, setActiveSpeaker] = useState(null);
  const [muted, setMuted] = useState(false);
  const [callDuration, setCallDuration] = useState(0);
  const [outputLevel, setOutputLevel] = useState(0);
  const intervalIdRef = useRef(null);
  const twilioCallIdRef = useRef(null);

  useEffect(() => {
    if (!callInProgress) {
      setCallDuration(0)
    }
  }, [callInProgress]);

  useEffect(() => {
    if (props.changeCurrentPhoneCallDuration && callInProgress) {
      props.changeCurrentPhoneCallDuration(callDuration, twilioCallIdRef.current)
    }
  }, [callDuration]);

  const makeCall = async () => {
    let createPhoneCallUrl
    if (coldCallingSession) {
      createPhoneCallUrl = `/phone_calls/create_phone_call/${props.scheduledPhoneCallId}?data_provider_information_id=${selectedDataProviderInformation.id}`
    } else {
      createPhoneCallUrl = `/phone_calls/create_prospect_phone_call/${props.prospectId}?data_provider_information_id=${selectedDataProviderInformation.id}`
    }

    let response = await axios.get(createPhoneCallUrl).catch((error) => {
      let { data, status } = error.response

      if (data.error = 'Prospects being distributed' && status == 422) {
        props.history.replace('/cold_calling', { flash: 'Prospects are being distributed, please wait a few minutes and try again.' })
      }

      if (status == 401) {
        props.history.replace('/cold_calling', { flash: 'You are not authorized to peform this call.' })
      }
    })

    if (!response) return;

    let phoneCallId = response?.data?.id

    let phoneNumber = selectedDataProviderInformation.userSanitizedValue || selectedDataProviderInformation.value
    if (selectedDataProviderInformation.userSanitizedValue || selectedDataProviderInformation.value) {
      const call = await device.connect({
        params: {
          To: phoneNumber,
          phoneCallId,
        }
      });
      setCallInProgress(call);

      call.on('accept', () => {
        // We need the call sid to update notes/create tasks, it's not available until the call is accepted by Twilio
        // (accepted is internal Twilio status, it's different from received)
        twilioCallIdRef.current = call.parameters.CallSid;

        if (props.setCallData) {
          props.setCallData({ callSid: twilioCallIdRef.current, callId: phoneCallId });
        }
        if (props.setCallInProgress) {
          props.setCallInProgress(call);
        }
        startCallDurationMeasurment();
        if (props.reloadScheduledPhoneCallPhoneCalls) {
          props.reloadScheduledPhoneCallPhoneCalls()
        }
      })

      call.on('disconnect', () => {
        setCallInProgress(null);
        stopCallDurationMeasurment();
        if (props.setCallInProgress) {
          props.setCallInProgress(false);
        }
        if (props.setCallData) {
          props.setCallData({ ...props.callData, callSid: twilioCallIdRef.current, ended: true, callId: phoneCallId });
        }
      });

      call.on('error', (error) => {
        console.error('Connection error:', error);
        setCallInProgress(null);
        if (props.reloadScheduledPhoneCallPhoneCalls) {
          props.reloadScheduledPhoneCallPhoneCalls()
        }
        if (props.setCallData) {
          props.setCallData({ callSid: twilioCallIdRef.current, ended: true, callId: phoneCallId });
        }
      });

      call.on('volume', (_inputVolume, outputVolume) => {
        setOutputLevel(outputVolume);
      })
    }
  };

  const endCall = () => {
    if (callInProgress) {
      callInProgress.disconnect();
    }
  };

  const sendDigit = (digit) => {
    if (callInProgress) {
      callInProgress.sendDigits(digit);
    }
  };

  const updateAudioDevices = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    await stream.getTracks().forEach(track => track.stop());

    const availableMicrophones = Array.from(device.audio.availableInputDevices)
    const availableSpeakers = Array.from(device.audio.availableOutputDevices)

    setMicrophones(availableMicrophones);
    setSpeakers(availableSpeakers);

    setActiveMicrophone(device.audio.inputDevice?.deviceId || 'default')
    setActiveSpeaker(Array.from(device.audio.speakerDevices.get())[0].deviceId)
  };

  const handleActiveMicrophoneChange = (deviceId) => {
    device.audio.setInputDevice(deviceId);
    setActiveMicrophone(deviceId)
  };

  const handleActiveSpeakerChange = (deviceId) => {
    device.audio.speakerDevices.set(deviceId);
    setActiveSpeaker(deviceId)
  };

  const startCallDurationMeasurment = () => {
    setCallDuration(0);
    const id = setInterval(() => {
      setCallDuration(prevCounter => prevCounter + 1);
    }, 1000);
    intervalIdRef.current = id;
  };

  const stopCallDurationMeasurment = () => {
    clearInterval(intervalIdRef.current);
    intervalIdRef.current = null;
  };

  useEffect(() => {
    updateAudioDevices();
    device.audio.on('deviceChange', () => updateAudioDevices());
  }, [device]);

  useEffect(() => {
    if (callInProgress) {
      callInProgress.mute(muted);
    }
  }, [muted]);

  return (
    <Grid>
      <Grid.Row style={{ marginTop: 20 }} verticalAlign="middle">
        <Grid.Column width={16}>
          <Button
            icon={callInProgress ? <i className="icon fa-solid fa-phone-slash"></i> : <i className="icon fa-solid fa-phone"></i>}
            color={callInProgress ? 'red' : 'green'}
            onClick={callInProgress ? endCall : makeCall}
            size="big"
            style={{ marginRight: 10 }}
          />

          {(callInProgress || callDuration > 0) && (
            <span style={{ marginRight: 10 }}>{formatTime(callDuration)}</span>
          )}

          {props.children}

          <Popup
            trigger={<Button icon={<Icon name="headphones" />} style={{ marginLeft: 10, marginRight: 5 }} />}
            on="click"
            position="bottom center"
            className="audioDevicesPopup"
          >
            <Grid>
              <Grid.Row>
                <Grid.Column width={16}>
                  <label>Microphone:</label>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Dropdown
                    fluid
                    search
                    selection
                    options={microphones.map(([deviceId, details]) => { return { key: deviceId, value: deviceId, text: details.label } })}
                    value={activeMicrophone}
                    onChange={(_e, element) => handleActiveMicrophoneChange(element.value)}
                    placeholder='Select Microphone'
                  />
                  <AudioMeter meteredDevice={activeMicrophone} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <label>Speaker:</label>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Dropdown
                    fluid
                    search
                    selection
                    options={speakers.map(([deviceId, details]) => { return { key: deviceId, value: deviceId, text: details.label } })}
                    value={activeSpeaker}
                    onChange={(_e, element) => handleActiveSpeakerChange(element.value)}
                    placeholder='Select Speaker'
                  />
                  <AudioMeter level={outputLevel} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Popup>

          {callInProgress && (
            <>
              <Popup
                trigger={<Button icon="keyboard" style={{ margin: 5 }}/>}
                on="click"
                position="bottom center"
                className="keyboardPopup"
              >
                <Grid columns={3}>
                  {'123456789*0#'.split('').map((digit) => (
                    <Grid.Column key={digit}>
                      <Button basic onClick={() => sendDigit(digit)}>
                        {digit}
                      </Button>
                    </Grid.Column>
                  ))}
                </Grid>
              </Popup>
              <Button
                icon="microphone slash"
                onClick={() => setMuted(!muted)}
                active={muted}
                style={{ margin: 5 }}
              />
            </>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default withRouter(TwilioCall);
