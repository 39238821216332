import React from 'react'
import { List, Segment, Grid, Icon } from 'semantic-ui-react'
import { present } from '../../shared/utils'

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};

const PhoneCalls = (props) => {
  const { phoneCalls } = props

  if (phoneCalls.length === 0) { return null }

  return(
    <>
      <h2>{props.title || 'Phone Calls'}</h2>

      <List divided relaxed>
        {phoneCalls.map((call, i) => (
          <Segment key={i}>
            <Grid divided verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={4}>
                  <List.Item>
                    <List.Header>
                      <b>User</b>
                      {call.scheduledPhoneCallId && (
                        <i title="Scheduled phone call part of cold calling campaign">
                          <Icon
                            name='calendar alternate outline'
                            style={{ float: 'right' }}
                          />
                        </i>
                      )}
                    </List.Header>
                    <p>{call.user.name}</p>
                  </List.Item>
                </Grid.Column>
                <Grid.Column width={3}>
                  <List.Item>
                    <List.Header><b>Phone Number</b></List.Header>
                    {call.phone}
                  </List.Item>
                </Grid.Column>
                <Grid.Column width={2}>
                  <List.Item>
                    <List.Header><b>Duration</b></List.Header>
                    {formatTime(call.duration)}
                  </List.Item>
                </Grid.Column>
                <Grid.Column width={2}>
                  <List.Item>
                    <List.Header><b>Status</b></List.Header>
                    {call.callStatus || 'undefined'}
                  </List.Item>
                </Grid.Column>
                <Grid.Column width={3}>
                  <List.Item>
                    <List.Header><b>Created At</b></List.Header>
                    {new Date(call.createdAt).toLocaleString()}
                  </List.Item>
                </Grid.Column>
                <Grid.Column stretched>
                  {call.phoneCallOutcome && (
                    <List.Item>
                      <List.Header><b>Outcome</b></List.Header>
                      {call.phoneCallOutcome.name}
                    </List.Item>
                  )}
                </Grid.Column>
              </Grid.Row>
              {present(call.notes) && (
                <Grid.Row>
                  <Grid.Column width={16} verticalAlign='middle'>
                    <b style={{ marginRight: 5 }}>Notes:</b>
                    {call.notes}
                  </Grid.Column>
                </Grid.Row>
              )}
              {present(call.recordingUrls) && (
                call.recordingUrls.map((url, i) => (
                  <Grid.Row key={i}>
                    <Grid.Column width={16} verticalAlign='middle'>
                      <audio controls preload='none'>
                        <source src={`/phone_calls/recording?id=${url}`} type="audio/wav" />
                        Your browser does not support the audio tag.
                      </audio>
                    </Grid.Column>
                  </Grid.Row>
                ))
              )}
            </Grid>
          </Segment>
        ))}
      </List>
    </>
  )
}

export default PhoneCalls
