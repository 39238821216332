import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, withRouter } from "react-router-dom";
import { Header, HeaderSubheader, Button, Form, TextArea, Dropdown, Grid, Segment, Message } from 'semantic-ui-react';
import axios from 'axios';
import TwilioDevice from '../../shared/Twilio/TwilioDevice';
import { present } from "../../shared/utils.js";
import PhoneCalls from './PhoneCalls';
import ProspectView from '../../shared/ProspectView.js';
import MotionDatePicker from '../../shared/MotionDatePicker.js';
import moment from 'moment';
import PhoneNumberControl from './PhoneNumberControl.js';
import nl2br from "react-nl2br";

const PROSPECT_DISTRIBUTION_FLASH = 'Prospects are being distributed, please wait a few minutes and try again.'

const CallInterface = (props) => {
  const { campaignGroupId, scheduledPhoneCallId } = props;
  const outcomes = props.phoneCallOutcomes;

  const [scheduledPhoneCall, setScheduledPhoneCall] = useState(props.scheduledPhoneCall || {})
  const [callData, setCallData] = useState({})
  const [callInProgress, setCallInProgress] = useState(false);
  const [displayErrors, setDisplayErrors] = useState(false);
  const [loading, setLoading] = useState(false);
  const pageChangeBlocker = useRef(null);

  useEffect(() => {
    if (!scheduledPhoneCallId) {
      fetchNextCall('replace');
    }

    return () => {
      if (pageChangeBlocker.current) {
        window.removeEventListener("beforeunload", pageChangeBlocker.current);
        pageChangeBlocker.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (scheduledPhoneCallId) {
      fetchCall();
    }
  }, [scheduledPhoneCallId])

  useEffect(() => {
    if (callInProgress && !pageChangeBlocker.current) {
      const onBeforeUnload = (ev) => {
        if (ev) {
          ev.preventDefault();
          ev.returnValue = 'Are you sure you want to leave?';
        }

        return 'Are you sure you want to leave?';
      }

      window.addEventListener("beforeunload", onBeforeUnload);

      pageChangeBlocker.current = onBeforeUnload;
    }
  }, [callInProgress]);

  useEffect(() => {
    if (callData.ended !== true || loading) return;

    const phoneCall = scheduledPhoneCall.phoneCalls.find(call => call.id === callData.callId)

    if (!phoneCall) return;

    // Based on the duration of the call, we are guessing if the call was connected or not
    if (phoneCall.duration <= 5) {
      phoneCall.connected = 'invalid-number'
    } else if (phoneCall.duration > 5 && phoneCall.duration <= 30) {
      phoneCall.connected = 'nobody-picked-up'
    } else {
      phoneCall.connected = 'yes'
    }

    setScheduledPhoneCall({ ...scheduledPhoneCall })
  }, [callData, loading]);

  const fetchCall = async () => {
    try {
      const response = await axios.get(`/cold_calling/${campaignGroupId}/${scheduledPhoneCallId}.json`);
      if (response.status === 204) {
        props.history.push(`/cold_calling`, { flash: 'Not found.' })
      } else {
        setScheduledPhoneCall({
          ...response.data,
          callbackTime: '',
          blacklistProspect: '',
        })
        let tempPhones = response.data.prospect?.dataProviderInformation?.filter(info => info.type === 'phone')
        setPhones(tempPhones)
        setSelectedDataProviderInformation(tempPhones[0])
      }
    } catch (error) {
      if (error.response.status == 307 && error.response.data.status == 'prospect_distribution_running') {
        props.history.replace(`/cold_calling`, { flash: PROSPECT_DISTRIBUTION_FLASH})
      } else if (error.response.status == 307 && error.response.data.status == 'scheduled_phone_call_locked') {
        props.history.replace(`/cold_calling`, { flash: "You cannot access this scheduled phone call because someone else is currently working on it." })
      } else {
        console.error('Failed to fetch call:', error, error.response.status, error.response.data);
      }
    }
  };

  const fetchNextCall = async (navigationMethod = 'push') => {
    try {
      const response = await axios.get(`/cold_calling/${campaignGroupId}/next`);
      if (response.status === 204) {
        props.history.push(`/cold_calling`, { flash: 'No more calls to process.' })
      } else {
        if (navigationMethod === 'push') {
          props.history.push(`/cold_calling/${campaignGroupId}/${response.data.id}`)
        } else {
          // We want to use replace only when the user been redirected to the next call page without
          // scheduledPhoneCallId in the URL. Ohterwise with `push` the user cannot go back to the original page.
          props.history.replace(`/cold_calling/${campaignGroupId}/${response.data.id}`)
        }
      }
    } catch (error) {
      if (error.response.status == 307 && error.response.data.status == 'prospect_distribution_running') {
        props.history.replace(`/cold_calling`, { flash: PROSPECT_DISTRIBUTION_FLASH})
      } else {
        console.error('Failed to fetch next call:', error);
      }
    }
  };

  const handleSave = async () => {
    let phoneCalls = scheduledPhoneCall.phoneCalls.filter(call => call.scheduledPhoneCallId == scheduledPhoneCallId)
    let saveData = {
      notes: scheduledPhoneCall.notes,
      phoneCallOutcomeId: scheduledPhoneCall.phoneCallOutcomeId,
      scheduledFor: scheduledPhoneCall.callbackTime,
      blacklistProspect: scheduledPhoneCall.blacklistProspect || false,
      phoneCallsAttributes: phoneCalls.map(call => ({
          id: call.id,
          connected: call.connected,
          companyOk: call.companyOk,
          personOk: call.personOk,
          gatekeeper: call.gatekeeper,
          gotThrough: call.gotThrough,
        })
      )
    }
    try {
      let response = await axios.post(`/cold_calling/${campaignGroupId}/${scheduledPhoneCallId}`, { scheduledPhoneCall: saveData });
      let data = response.data;
      if (data.success) {
        setDisplayErrors(false)
        if (pageChangeBlocker.current) {
          window.removeEventListener("beforeunload", pageChangeBlocker.current);
          pageChangeBlocker.current = null;
        }
        if (!callInProgress) {
          fetchNextCall();
        }
      } else {
        setDisplayErrors(true)
      }
    } catch (error) {
      console.error('Failed to save prospect:', error);
    }
  };

  const reloadScheduledPhoneCallPhoneCalls = async () => {
    setLoading(true)
    try {
      const response = await axios.get(`/cold_calling/${campaignGroupId}/${scheduledPhoneCallId}.json`);
      let scheduledPhoneCallPhoneCalls = scheduledPhoneCall.phoneCalls
      response.data.phoneCalls.forEach(call => {
        let phoneCall = scheduledPhoneCallPhoneCalls.find(c => c.id === call.id)
        // NOTE how to prevent this overriding the stuff from callData useEffect?
        if (!phoneCall) {
          scheduledPhoneCallPhoneCalls = [call].concat(scheduledPhoneCallPhoneCalls)
        } else {
          phoneCall.duration = call.duration
        }
      })
      setScheduledPhoneCall({ ...scheduledPhoneCall, phoneCalls: scheduledPhoneCallPhoneCalls })
    } catch (error) {
      console.error('Failed to reload scheduled phone call:', error);
    }
    setLoading(false)
  };

  const changeCurrentPhoneCallDuration = (duration, phoneCallTwilioId) => {
    let phoneCall = scheduledPhoneCall.phoneCalls.find(call => call.twilioId === phoneCallTwilioId)
    if (phoneCall) {
      phoneCall.duration = duration
      setScheduledPhoneCall({ ...scheduledPhoneCall })
    }
  }

  const handleScheduledPhoneCallChange = (e, { name, value }) => {
    let newCallbackTime = scheduledPhoneCall.callbackTime
    if (name === "phoneCallOutcomeId" && !scheduledPhoneCall.callbackTime) {
      let outcome = outcomes.find(o => o.id === value)
      if (outcome.name === "Callback") {
        newCallbackTime = 'in_3_days'
      }
    }
    setScheduledPhoneCall({ ...scheduledPhoneCall, [name]: value, callbackTime: newCallbackTime, });
  };

  const handleCallbackTimeChange = (date) => {
    let callbackTime
    if (date.range !== 'custom' && date.range !== 'no_callback') {
      callbackTime = date.range
    } else if (date.range === 'custom') {
      callbackTime = date.from
    } else {
      callbackTime = null
    }

    setScheduledPhoneCall({ ...scheduledPhoneCall, callbackTime: callbackTime });
  };

  const setScheduledPhoneCallPhoneCalls = (phoneCalls) => {
    setScheduledPhoneCall({ ...scheduledPhoneCall, phoneCalls });
  }

  const [phones, setPhones] = useState(scheduledPhoneCall.prospect?.dataProviderInformation?.filter(info => info.type === 'phone') || [])
  const [selectedDataProviderInformation, setSelectedDataProviderInformation] = useState(phones[0] || {});

  if (!scheduledPhoneCallId) return null;
  if (!present(scheduledPhoneCall)) return null;

  let previousScheduledPhoneCalls = scheduledPhoneCall.scheduledPhoneCalls
    .filter(s => Number(s.id) !== Number(scheduledPhoneCallId))
    .filter(s => {
      if (scheduledPhoneCall.completedAt && !s.completedAt) return false
      return moment(s.scheduledFor || s.createdAt).isBefore(scheduledPhoneCall.completedAt || scheduledPhoneCall.scheduledFor || scheduledPhoneCall.createdAt)
    });

  let notesFromPreviousCallsHtml = previousScheduledPhoneCalls.map(spc => {
    let scheduledPhoneDate = moment(spc.scheduledFor || scheduledPhoneCall.createdAt).format('DD.MM.YYYY')
    let outcomeText = spc.phoneCallOutcomeId && outcomes.find(outcome => outcome.id === spc.phoneCallOutcomeId)?.name
    outcomeText ||= 'Callback'

    if (Number(spc.id) === Number(scheduledPhoneCallId)) return null
    if (!spc.notes || spc.notes.length === 0) return null

    return (
      <p key={spc.id} style={{marginBottom: 10}}>
        <i>{scheduledPhoneDate}, {outcomeText}:</i><br></br> {spc.notes}
      </p>
    )
  }).filter(x => x)

  let daysSinceScheduled = moment(scheduledPhoneCall.scheduledFor).diff(moment({ hours: 0 }), 'days')

  return (
      <Grid divided style={{ marginTop: 15 }}>
        <Grid.Column width={8} style={{ marginTop: 25 }}>
          <ProspectView
            id={scheduledPhoneCall.prospect.id}
            crmStatuses={props.crmStatuses}
            users={props.users}
            currentUserId={props.currentUserId}
            campaigns={props.campaigns}
            tags={props.tags}
            onProspectChange={() => {}}
            renderColdCallingCardView={true}
          />
        </Grid.Column>
        <Grid.Column width={8}>
          <Form error={displayErrors}>
            <TwilioDevice
              prospectId={scheduledPhoneCall.prospect.id}
              scheduledPhoneCallId={scheduledPhoneCallId}
              coldCallingSession={true}
              callData={callData}
              setCallData={setCallData}
              setCallInProgress={setCallInProgress}
              reloadScheduledPhoneCallPhoneCalls={reloadScheduledPhoneCallPhoneCalls}
              changeCurrentPhoneCallDuration={changeCurrentPhoneCallDuration}
              selectedDataProviderInformation={selectedDataProviderInformation}
            >
              <PhoneNumberControl
                phones={phones}
                setPhones={setPhones}
                scheduledPhoneCallId={scheduledPhoneCallId}
                prospectId={scheduledPhoneCall.prospect.id}
                selectedDataProviderInformation={selectedDataProviderInformation}
                setSelectedDataProviderInformation={setSelectedDataProviderInformation}
                callInProgress={callInProgress}
              />
            </TwilioDevice>
            <Segment>
              <Header as='h3'>
                Scheduled Phone Call
                <HeaderSubheader>
                  #{previousScheduledPhoneCalls.length+1} for this prospect
                  {daysSinceScheduled > 0 && (<i> scheduled for {daysSinceScheduled} day{daysSinceScheduled > 1 && 's'} from now</i>)}
                </HeaderSubheader>
              </Header>
              <b>Campaign</b>
              <p>{scheduledPhoneCall.phoneCallCampaignGroupAction.campaignGroup.name} - {scheduledPhoneCall.messageVariant.name}</p>
              <b>Script</b>
              <p>{ nl2br(scheduledPhoneCall.messageVariant.callDescription) }</p>
              { notesFromPreviousCallsHtml.length > 0 && (
                <React.Fragment>
                  { <b>Notes from previous calls:</b> }
                  { notesFromPreviousCallsHtml }
                </React.Fragment>
              )}
            </Segment>

            {scheduledPhoneCall.phoneCalls.length > 0 && (
              <Segment>
                <h3>Calls</h3>
                <PhoneCalls
                  campaignGroupId={campaignGroupId}
                  currentScheduledPhoneCallId={scheduledPhoneCallId}
                  phoneCalls={scheduledPhoneCall.phoneCalls}
                  setScheduledPhoneCallPhoneCalls={setScheduledPhoneCallPhoneCalls}
                  currentCall={callData}
                  callInProgress={callInProgress}
                  displayErrors={displayErrors}
                  scheduledPhoneCalls={scheduledPhoneCall.scheduledPhoneCalls}
                  outcomes={outcomes}
                />
              </Segment>
            )}

            <Segment>
              <h3>Outcome</h3>
              <Form.Field required>
                <label>Call Outcome</label>
                <Dropdown
                  error={displayErrors && scheduledPhoneCall.phoneCallOutcomeId == null}
                  fluid
                  selection
                  options={outcomes.map(o => ({ key: o.id, value: o.id, text: o.name }))}
                  style={{ marginBottom: 10 }}
                  value={scheduledPhoneCall.phoneCallOutcomeId}
                  onChange={(_e, element) => handleScheduledPhoneCallChange(_, { name: 'phoneCallOutcomeId', value: element.value })}
                  id="phone-call-outcome-dropdown"
                />
              </Form.Field>

              <Form.Field>
                <Form.Field
                  control={TextArea}
                  label='Call Notes'
                  name='notes'
                  value={scheduledPhoneCall.notes || ''}
                  onChange={handleScheduledPhoneCallChange}
                />
              </Form.Field>

              <Form.Field>
                <MotionDatePicker
                  range={scheduledPhoneCall.callbackTime instanceof Date ? "custom" : scheduledPhoneCall.callbackTime}
                  label="Callback Time"
                  onChange={handleCallbackTimeChange}
                  showTimeSelect={true}
                  options={[
                    { key: 'no_callback', value: 'no_callback', text: 'No callback' },
                    { key: 'in_3_days', value: 'in_3_days', text: 'In 3 days' },
                    { key: 'custom', value: 'custom', text: 'Custom' }
                  ]}
                  id="callback-time-dropdown"
                />
              </Form.Field>

            <Form.Field>
              <label>Blacklist Prospect</label>
              <Dropdown
                fluid
                selection
                options={[{ key: 'no', value: false, text: 'No' }, { key: 'yes', value: true, text: 'Yes' }]}
                style={{ marginBottom: 10 }}
                value={scheduledPhoneCall.blacklistProspect}
                onChange={(_e, element) => handleScheduledPhoneCallChange(_, { name: 'blacklistProspect', value: element.value })}
                id="blacklist-prospect-dropdown"
              />
            </Form.Field>

            </Segment>
            <Message
              error
              header='Cannot save changes'
              content='Please fill out all required fields.'
            />
            <Form.Field
              control={Button}
              content={callInProgress ? 'Save' : 'Save & Goto Next'}
              onClick={handleSave}
              style={{ width: '100%', }}
            />
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default withRouter(CallInterface);
