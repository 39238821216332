import { truncate } from "lodash";
import axios from "axios";

const SEND_TIMEOUT = 10000;

const onLocationChange = (callback = () => {}) => {
  let oldHref;

  const observer = new MutationObserver(function (mutations) {
    mutations.forEach(() => {
      if (oldHref != document.location.href) {
        callback(document.location.href, oldHref);
        oldHref = document.location.href;
      }
    });
  });

  observer.observe(document.querySelector("body"), {
    childList: true,
    subtree: true,
  });
};

export default ({ sendTimeoutOverwrite = null }) => {
  let eventBuffer = [];
  let mouseMoveStarted,
    mouseMoveDebounceTimer,
    scrollStarted,
    scrollDebounceTimer,
    keyDownStarted,
    keyDownDebounceTimer,
    sendBufferTimer;

  const sendEvents = (events) => {
    if (events.length === 0) return;
    axios.post(document.location.pathname.match(/backend/) ? "/backend/ping" : "/ping", {
      e: Buffer(JSON.stringify(events)).toString("base64"),
    });
  };

  const pushEvent = (type, data = {}) => {
    eventBuffer.push([type, { ...data, href: window.location.href, }, new Date().getTime()]);

    if (sendBufferTimer) clearTimeout(sendBufferTimer);

    if (eventBuffer.length >= 20) {
      sendEvents(eventBuffer);
      eventBuffer = [];
      return;
    }

    sendBufferTimer = setTimeout(() => {
      sendEvents(eventBuffer);
      eventBuffer = [];
    }, sendTimeoutOverwrite || SEND_TIMEOUT);
  };

  const handleKeyDown = () => {
    if (!keyDownDebounceTimer) {
      keyDownStarted = new Date();
    } else {
      clearTimeout(keyDownDebounceTimer);
    }

    keyDownDebounceTimer = setTimeout(() => {
      pushEvent("KEYS_PRESSED", {
        started: keyDownStarted.getTime(),
        ended: new Date().getTime(),
      });
    }, 1000);
  };

  const handleScroll = () => {
    if (!scrollDebounceTimer) {
      scrollStarted = new Date();
    } else {
      clearTimeout(scrollDebounceTimer);
    }

    scrollDebounceTimer = setTimeout(() => {
      pushEvent("SCROLL", {
        started: scrollStarted.getTime(),
        ended: new Date().getTime(),
      });
    }, 1000);
  };

  const handleMouseMove = () => {
    if (!mouseMoveDebounceTimer) {
      mouseMoveStarted = new Date();
    } else {
      clearTimeout(mouseMoveDebounceTimer);
    }

    mouseMoveDebounceTimer = setTimeout(() => {
      pushEvent("MOUSE_MOVES", {
        started: mouseMoveStarted.getTime(),
        ended: new Date().getTime(),
      });
    }, 1000);
  };

  const handleMouseClick = ({ target: { textContent, classList } }) => {
    pushEvent("MOUSE_CLICK", {
      nodeText: truncate(textContent, { length: 80 }),
      nodeClassList: classList.value,
    });
  };

  window.onload = () => {
    onLocationChange((href, before) => {
      pushEvent("URL_CHANGE", { to: href, from: before });
    });
    document.querySelector("body").addEventListener("click", handleMouseClick);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("wheel", handleScroll);
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("beforeunload", () => sendEvents(eventBuffer));
  };
};
