import React, { useState, useRef, useEffect } from "react";
import { Dropdown, Grid, Icon, Segment, List, Form, Divider } from 'semantic-ui-react';
import { present } from "../../shared/utils.js";
import moment from 'moment';
import axios from 'axios';

const PhoneCalls = (props) => {
  const [displayOthers, setDisplayOthers] = useState(false);
  const [currentCallDropdownFocused, setCurrentCallDropdownFocused] = useState(false);

  useEffect(() => {
    if (!props.callInProgress && currentCallConnectedInputRef.current && !currentCallDropdownFocused) {
      currentCallConnectedInputRef.current.focus()
      setCurrentCallDropdownFocused(true)
    }
  }, [props.callInProgress])

  useEffect(() => {
    if (currentCallDropdownFocused) {
      setCurrentCallDropdownFocused(false)
    }
  }, [props.callInProgress])

  const {
    currentScheduledPhoneCallId, callInProgress, phoneCalls,
    currentCall, displayErrors, scheduledPhoneCalls, outcomes,
    campaignGroupId,
  } = props;

  const currentCallConnectedInputRef = useRef(null);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const updateCall = (attribute, value, callId) => {
    let phoneCall = phoneCalls.find(call => call.id === callId)
    phoneCall[attribute] = value;
    props.setScheduledPhoneCallPhoneCalls([...phoneCalls])
  }

  const pollForRecordings = (call) => {
    let phoneCall = phoneCalls.find(c => c.id === call.id)

    axios.get(`/phone_calls/recordings/${call.id}/`).then(({ data }) => {
      if (data.length > 0) {
        console.log('polled data is', data)
        phoneCall['recordingUrls'] = data;
        props.setScheduledPhoneCallPhoneCalls([...phoneCalls])
      } else {
        setTimeout(() => pollForRecordings(call), 3000)
      }
    }).catch(error => {
      console.error('error while polling for recordings', error)
    })
  }

  useEffect(() => {
    phoneCalls.forEach(call => {
      if (call.connected === 'yes' && (!call.recordingUrls || call.recordingUrls.length === 0)) {
        pollForRecordings(call);
      }
    });
  }, [phoneCalls]);

  const renderPhoneCall = (call, i, currentCallSid) => {
    let disableInputs = currentScheduledPhoneCallId != call.scheduledPhoneCallId

    return (
      <Segment key={i} id={`phone-call-${call.id}`}>
        <Grid verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={10}>
              <List.Item>
                <List.Header>
                  <Icon name='user' /> {call?.user?.name}
                </List.Header>
              </List.Item>
            </Grid.Column>
            <Grid.Column width={1} floated='right' style={{ marginRight: 10 }}>
              {callInProgress && currentCallSid === call.twilioId && (
                <Icon name='volume control phone' color="green" title="Ongoing call" />
              )}
              {(!callInProgress || currentCallSid !== call.twilioId) && call.connected == 'yes' && (
                <Icon name='phone' color="blue" title="Connected" />
              )}
              {(!callInProgress || currentCallSid !== call.twilioId) && call.connected != 'yes' && (
                <Icon name='phone' color="red" title="Unconnected" />
              )}
            </Grid.Column>
            <Grid.Column width={16}>
              <List.Item>
                <List.Header>
                  {/* We could display the phone number from call.dataProviderInformation but it's value could be
                  changed after the call is made. `phone` is the value used by Twilio to make the call. */}
                  <>
                    <Icon name='phone' />{call.phone}
                  </>
                </List.Header>
              </List.Item>
            </Grid.Column>
            <Grid.Column width={16}>
              <List.Item>
                <List.Header>
                  <Icon name='clock' />
                  {new Date(call.createdAt).toLocaleString()}&nbsp;
                </List.Header>
              </List.Item>
            </Grid.Column>
            <Grid.Column width={16}>
              <List.Item>
                <List.Header>
                  <Icon name='hourglass outline' />
                  {formatTime(call.duration)}
                </List.Header>
              </List.Item>
            </Grid.Column>
          </Grid.Row>

          {present(call.notes) && (
            <Grid.Row>
              <Grid.Column width={16} verticalAlign='middle'>
                <b style={{ marginRight: 5 }}>Notes:</b>
                {call.notes}
              </Grid.Column>
            </Grid.Row>
          )}

          {present(call.recordingUrls) && (
            call.recordingUrls.map((url, i) => (
              <Grid.Row key={i}>
                <Grid.Column width={16} verticalAlign='middle'>
                  <audio controls preload='none'>
                    <source src={`/phone_calls/recording?id=${url}`} type="audio/wav" />
                    Your browser does not support the audio tag.
                  </audio>
                </Grid.Column>
              </Grid.Row>
            ))
          )}
          <Grid.Row>
            <Grid.Column width={16}>
              <Form.Field required>
                <label>Connected?</label>
                {currentCallSid === call.twilioId && (
                  <Dropdown
                    selection
                    fluid
                    options={[
                      { key: 'invalid-number', text: 'No - Number cannot be called', value: 'invalid-number' },
                      { key: 'nobody-picked-up', text: 'No - Nobody picked up', value: 'nobody-picked-up' },
                      { key: 'yes', text: 'Yes', value: 'yes' },
                    ]}
                    onChange={(_e, element) => updateCall('connected', element.value, call.id)}
                    value={call.connected}
                    error={displayErrors && call.connected == null}
                    disabled={disableInputs}
                    ref={currentCallConnectedInputRef}
                    className="connected-dropdown"
                  />
                )}
                {currentCallSid !== call.twilioId && (
                  <Dropdown
                    selection
                    fluid
                    options={[
                      { key: 'invalid-number', text: 'No - Number cannot be called', value: 'invalid-number' },
                      { key: 'nobody-picked-up', text: 'No - Nobody picked up', value: 'nobody-picked-up' },
                      { key: 'yes', text: 'Yes', value: 'yes' },
                    ]}
                    onChange={(_e, element) => updateCall('connected', element.value, call.id)}
                    value={call.connected}
                    error={displayErrors && call.connected == null}
                    disabled={disableInputs}
                    className="connected-dropdown"
                  />
                )}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          {call.connected === 'yes' && (
            <>
              <Grid.Row style={{ paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                <Grid.Column width={8}>
                  <Form.Field required>
                    <label>Company ok?</label>
                    <Dropdown
                      placeholder='Company ok?'
                      selection
                      options={[
                        { key: 'yes', text: 'Yes', value: true },
                        { key: 'no', text: 'No', value: false },
                      ]}
                      onChange={(_e, element) => updateCall('companyOk', element.value, call.id)}
                      value={call.companyOk}
                      error={displayErrors && call.companyOk == null}
                      disabled={disableInputs}
                      className="company-ok-dropdown"
                    />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Field required>
                    <label>Gatekeeper?</label>
                    <Dropdown
                      placeholder='Gatekeeper?'
                      selection
                      options={[
                        { key: 'yes', text: 'Yes', value: true },
                        { key: 'no', text: 'No', value: false },
                      ]}
                      onChange={(_e, element) => updateCall('gatekeeper', element.value, call.id)}
                      value={call.gatekeeper}
                      error={displayErrors && call.gatekeeper == null}
                      disabled={disableInputs}
                      className="gatekeeper-dropdown"
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ paddingTop: '0.5em' }}>
                <Grid.Column width={8}>
                  <Form.Field required>
                    <label>Person ok?</label>
                    <Dropdown
                      placeholder='Person ok?'
                      selection
                      options={[
                        { key: 'yes', text: 'Yes', value: true },
                        { key: 'no', text: 'No', value: false },
                      ]}
                      onChange={(_e, element) => updateCall('personOk', element.value, call.id)}
                      value={call.personOk}
                      error={displayErrors && call.personOk == null}
                      disabled={disableInputs}
                      className="person-ok-dropdown"
                    />
                  </Form.Field>
                </Grid.Column>
                {call.gatekeeper === true && (
                  <Grid.Column width={8}>
                    <Form.Field required>
                      <label>Got through?</label>
                      <Dropdown
                        placeholder='Got through?'
                        selection
                        options={[
                          { key: 'yes', text: 'Yes', value: 'yes' },
                          { key: 'yes-but-nobody-picked-up', text: 'Yes - But nobody picked up', value: 'yes-but-nobody-picked-up' },
                          { key: 'no', text: 'No', value: 'no' },
                        ]}
                        onChange={(_e, element) => updateCall('gotThrough', element.value, call.id)}
                        value={call.gotThrough}
                        error={displayErrors && call.gotThrough == null}
                        disabled={disableInputs}
                        className="got-through-dropdown"
                      />
                    </Form.Field>
                  </Grid.Column>
                )}
              </Grid.Row>
            </>
          )}
        </Grid>
      </Segment>
    )
  }

  const phoneCallsGroupedByScheduledPhoneCall = Object.groupBy(phoneCalls, ({ scheduledPhoneCallId }) => scheduledPhoneCallId)
  const renderCurrentScheduledPhoneCall = (scheduledPhoneCall) => {
    if (!phoneCallsGroupedByScheduledPhoneCall[scheduledPhoneCall.id]) {
      return (
        <p>No phone calls made for this scheduled phone call.</p>
      )
    } else {
      return (
        <div key={scheduledPhoneCall.id}>
          {
            phoneCallsGroupedByScheduledPhoneCall[scheduledPhoneCall.id].map((phoneCall, i) =>
              renderPhoneCall(phoneCall, i, currentCall?.callSid)
            )
          }
        </div>
      )
    }
  }

  const renderScheduledPhoneCall = (scheduledPhoneCall, i) => {
    if (!phoneCallsGroupedByScheduledPhoneCall[scheduledPhoneCall.id]) {
      return null
    }

    let scheduledPhoneDate = moment(scheduledPhoneCall.completedAt || scheduledPhoneCall.scheduledFor || scheduledPhoneCall.createdAt).format('DD.MM.YYYY')
    let outcomeText = scheduledPhoneCall.phoneCallOutcomeId && outcomes.find(outcome => outcome.id === scheduledPhoneCall.phoneCallOutcomeId)?.name
    outcomeText ||= '[none yet]'

    return (
      <div key={i}>
        <Divider section horizontal>
          <a href={`/cold_calling/${campaignGroupId}/${scheduledPhoneCall.id}`}>{scheduledPhoneDate}, {outcomeText}</a>
        </Divider>
        {
          phoneCallsGroupedByScheduledPhoneCall[scheduledPhoneCall.id].map((phoneCall, i) =>
            <>
              {renderPhoneCall(phoneCall, i, currentCall?.callSid)}
            </>
          )
        }
      </div>
    )
  }

  return (
    <List divided relaxed>
      <Divider section horizontal>
        <span>Current</span>
      </Divider>
      {renderCurrentScheduledPhoneCall({ id: currentScheduledPhoneCallId })}

      {displayOthers && (
        <>
          {scheduledPhoneCalls.map((scheduledPhoneCall, i) =>
            (Number(scheduledPhoneCall.id) != Number(currentScheduledPhoneCallId)) ? renderScheduledPhoneCall(scheduledPhoneCall, i) : null
          )}
        </>
      )}
      <div style={{ cursor: 'pointer', textAlign: 'center', marginTop: 20 }}>
        {Object.keys(phoneCallsGroupedByScheduledPhoneCall).filter(id => Number(id) != Number(props.currentScheduledPhoneCallId)).length > 0 && (
          <a onClick={() => setDisplayOthers(!displayOthers)} >
            {displayOthers ? 'Hide' : 'View'} Others
            <i className={displayOthers ? "caret up icon" : "caret down icon"}></i>
          </a>
        )}
      </div>
    </List>
  )
}

export default PhoneCalls;
