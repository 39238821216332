import React, { useState, useEffect } from 'react';
import { Device } from '@twilio/voice-sdk';
import TwilioCall from './TwilioCall';

const TwilioDevice = (props) => {
  const [device, setDevice] = useState(null);

  useEffect(() => {
    const initializeDevice = async () => {
      try {
        const response = await fetch('/twilio/token');
        const data = await response.json();
        const newDevice = new Device(data.token, {
          logLevel: 3,
          // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
          // providing better audio quality in restrained network conditions.
          codecPreferences: ["opus", "pcmu"]
        });

        newDevice.on('ready', () => {
          console.log('Device is ready');
        });

        newDevice.on('error', (error) => {
          console.error('Device error:', error);
        });

        newDevice.on('connect', (conn) => {
          setConnection(conn);
        });

        newDevice.on('disconnect', (conn) => {
          setConnection(null);
        });

        setDevice(newDevice);
      } catch (error) {
        console.error('Failed to initialize device:', error);
      }
    };

    initializeDevice();
  }, []);

  return (
    <TwilioCall {...props} device={device} />
  );
};

export default TwilioDevice;
