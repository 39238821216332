import React, { useEffect, useState } from 'react';
import { Button, Input, Popup, Table, Icon } from 'semantic-ui-react';
import axios from 'axios';

const PhoneNumberControl = (props) => {
  const { phones, setPhones, callInProgress, scheduledPhoneCallId, prospectId, selectedDataProviderInformation, setSelectedDataProviderInformation } = props;

  const [phoneNumber, setPhoneNumber] = useState(selectedDataProviderInformation.userSanitizedValue || selectedDataProviderInformation.value || '');
  const [addingPhoneNumber, setAddingPhoneNumber] = useState(false);
  const [phoneNumberToAdd, setPhoneNumberToAdd] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setPhoneNumber(selectedDataProviderInformation.userSanitizedValue || selectedDataProviderInformation.value || '');
  }, [selectedDataProviderInformation]);

  const updatePhoneNumber = (value) => {
    setPhoneNumber(value)
    axios.post(
      `/data_provider_information/${selectedDataProviderInformation.id}`,
      { prospectId: prospectId, userSanitizedValue: value }
    )
    let newValue = { ...selectedDataProviderInformation, userSanitizedValue: phoneNumber, value: phoneNumber }
    // code smell, it's better to have a single data storage, and only reference that using ids, that way we wouldn't have
    // to update two things here
    let newPhones = [...phones]
    newPhones[newPhones.findIndex(p => p.id === selectedDataProviderInformation.id)] = newValue
    setPhones(newPhones)
    setSelectedDataProviderInformation(newValue)
  }

  const forbidPhoneNumber = (id) => {
    if (window.confirm('Are you sure you want to forbid this phone number?')) {
      setModalOpen(false)
      axios.post(
        `/data_provider_information/${id}/forbid`,
        { prospectId, scheduledPhoneCallId }
      ).then(() => {
        let newPhones = phones.filter(p => p.id != id)
        setPhones(newPhones)
        if (selectedDataProviderInformation.id == id) {
          setSelectedDataProviderInformation(newPhones[0])
        }
      }).catch(error => {
        console.error('Can not forbid phone number: ', error)
      })
    }
  }

  const handlePhoneCreation = () => {
    axios.post(
      '/data_provider_information',
      { type: 'phone', value: phoneNumberToAdd, prospectId: prospectId }
    ).then((data) => {
      setPhones([...phones, data.data])
      setAddingPhoneNumber(false)
      setPhoneNumberToAdd('')
    }).catch(error => {
      console.error("error when creating data provider information ", error);
    });
  }

  return (
    <>
      {callInProgress && (
        <Input
          value={phoneNumber}
          disabled
          icon='dropdown'
        />
      )}
      {!callInProgress && (
        <Popup
          trigger={
            <Input
              icon='dropdown'
              value={phoneNumber}
              onChange={(e) => {
                updatePhoneNumber(e.target.value)
                setSelectedDataProviderInformation({ ...selectedDataProviderInformation, userSanitizedValue: e.target.value, value: e.target.value });
              }}
              id="phone-number-popup-trigger"
              autoComplete="off"
            />
          }
          on="click"
          position="bottom center"
          wide={"very"}
          style={{ marginTop: 18 }}
          onClose={() => setModalOpen(false)}
          onOpen={() => setModalOpen(true)}
          open={modalOpen}
          id="phone-number-popup"
        >
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Phone</Table.HeaderCell>
                <Table.HeaderCell>Source</Table.HeaderCell>
                <Table.HeaderCell>Calls</Table.HeaderCell>
                <Table.HeaderCell>Quality</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {phones.map(phone => (
                <Table.Row key={phone.id} id={`dpi-${phone.id}`}>
                  <Table.Cell>
                    {phone.userSanitizedValue || phone.value}
                  </Table.Cell>
                  <Table.Cell>{phone.humanizedDataProviderType}</Table.Cell>
                  <Table.Cell>{phone.callCount || 0}</Table.Cell>
                  <Table.Cell>{phone.quality || 'Unknown'}</Table.Cell>
                  <Table.Cell>
                    <p
                      style={{ cursor: 'pointer', color: 'rgb(65, 131, 196)' }}
                      onClick={() => {
                        setSelectedDataProviderInformation(phone)
                        setModalOpen(false)
                      }}
                    >
                      Use
                    </p>
                    <p
                      style={{ cursor: 'pointer', color: 'rgb(65, 131, 196)' }}
                      onClick={() => forbidPhoneNumber(phone.id)}
                    >
                      Forbidden
                    </p>
                  </Table.Cell>
                </Table.Row>
              ))}
              {addingPhoneNumber && (
                <Table.Row key=''>
                  <Table.Cell>
                    <input
                      type='tel'
                      value={phoneNumberToAdd}
                      onChange={(e) => setPhoneNumberToAdd(e.target.value)}
                    />
                  </Table.Cell>
                  <Table.Cell>You</Table.Cell>
                  <Table.Cell>-</Table.Cell>
                  <Table.Cell>-</Table.Cell>
                  <Table.Cell>
                    <p
                      style={{ cursor: 'pointer', color: 'rgb(65, 131, 196)' }}
                      onClick={() => handlePhoneCreation()}
                    >
                      Save
                    </p>
                    <p
                      style={{ cursor: 'pointer', color: 'rgb(65, 131, 196)' }}
                      onClick={() => {
                        setAddingPhoneNumber(false)
                        setPhoneNumberToAdd('')
                      }}
                    >
                      Cancel
                    </p>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          {!addingPhoneNumber && (
            <p
              style={{ cursor: 'pointer', color: 'rgb(65, 131, 196)' }}
              onClick={() => {
                setAddingPhoneNumber(true)
                setPhoneNumberToAdd('')
              }}
            >
              Add Phone Number
            </p>
          )}
        </Popup>
      )}
    </>

  )
}

export default PhoneNumberControl;
